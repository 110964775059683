import { Component, OnInit, OnDestroy } from '@angular/core';
import {NavigationEnd, NavigationStart, NavigationError, Router, RouterEvent, NavigationCancel} from '@angular/router';
import {PacProgressbarService} from '@org.piercecountywa/pac-angular/pac-services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{
  subscriptions;
  constructor(
    private router: Router,
    private pacProgressbarService: PacProgressbarService
  ) {}

  ngOnInit() {
    // listen for router events and send to navigatonInterceptor
    this.subscriptions = this.router.events.subscribe((e : RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      // Show loading indicator
      this.pacProgressbarService.start();
    }
    if (event instanceof NavigationEnd) {
      // Hide loading indicator
      this.pacProgressbarService.complete();
    }
    if (event instanceof NavigationCancel) {
      // Hide loading indicator
      this.pacProgressbarService.complete();
    }
    if (event instanceof NavigationError) {
      // Hide loading indicator
      this.pacProgressbarService.complete();
    }
  }
}
