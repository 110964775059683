import {ISiteMap} from '@org.piercecountywa/pac-angular/hybrid';
import {SEARCH_SITEMAP_CONFIG} from '@root/search/search-site-map.config';

let PODS_SITEMAP_CONFIG:{
    [key: string]: ISiteMap;
} = {
  v2: {
    urlPattern: 'app/v2',
    srefPattern: 'v2',
    children: {
      ...SEARCH_SITEMAP_CONFIG
    }
  }
};

export default PODS_SITEMAP_CONFIG;
