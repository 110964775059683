import { ISiteMap } from '@org.piercecountywa/pac-angular';

export const SEARCH_SITEMAP_CONFIG = {
  search: (<ISiteMap> {
    urlPattern: 'search',
    srefPattern: 'search',
    children: {
      surveyDocument: {
        urlPattern: 'surveyDocument',
        srefPattern: 'surveyDocument'
      },
      commissionerRecords: {
        urlPattern: 'commissionerRecords',
        srefPattern: 'commissionerRecords'
      }
    }
  })
};
