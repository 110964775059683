import { BrowserModule } from '@angular/platform-browser';
import {ApplicationRef, DoBootstrap, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {UpgradeModule} from '@angular/upgrade/static';
import {setUpLocationSync} from '@angular/router/upgrade';
import PODS_SITEMAP_CONFIG from '@core/site-map-config';
import {DowngradeService,Ng1Ng2UrlHandlingStrategy,SiteMapService} from '@org.piercecountywa/pac-angular/hybrid';
import {UrlHandlingStrategy} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpErrorInterceptor, PacModalModule, PacTwinkieButtonModule} from '@org.piercecountywa/pac-angular';
import {environment} from '../environments/environment';
import {PacSaveModule} from '@org.piercecountywa/pac-angular/pac-save';
import {PacToastrModule} from '@org.piercecountywa/pac-angular/pac-toastr';
import {SiteMapRegistrationService} from '@org.piercecountywa/pac-angular/sitemap-registration';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    UpgradeModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PacModalModule,
    PacSaveModule.forRoot({haltOnError: true}),
    PacToastrModule,
    PacModalModule,
    PacTwinkieButtonModule,
    CommonModule

  ],
  providers: [
    {provide: UrlHandlingStrategy, useClass: Ng1Ng2UrlHandlingStrategy},
    {provide: 'HYBRID_ROUTER_DEBUG_ENABLED', useValue: !environment.production},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    { provide: Window, useValue: window }
  ],
  entryComponents: [AppComponent]
})
export class AppModule implements DoBootstrap {
  readonly ANGULAR_JS_ROOT_MODULE_NAME = 'pods';

  constructor(
    private upgrade: UpgradeModule,
    private siteMap: SiteMapService,
    private downgradeService: DowngradeService,
    private siteMapRegistrationService: SiteMapRegistrationService
  ) {
    //this.siteMap.addAll(PAC_ANGULAR_CONFIG); //TODO: Uncomment once library is available
    this.siteMap.addAll(PODS_SITEMAP_CONFIG);
    siteMapRegistrationService.registerLibraryRoutes();
  }

  ngDoBootstrap(app: ApplicationRef) {
    this.downgradeService.register('SiteMapService', this.siteMap);

    this.upgrade.bootstrap(document.documentElement, [this.ANGULAR_JS_ROOT_MODULE_NAME], {strictDi: true});
    setUpLocationSync(this.upgrade);

    const mutationObserver:MutationObserver = new MutationObserver((mutationList: MutationRecord[], observer: MutationObserver) => {
      if (document.getElementsByTagName('app-root').length) {
        app.bootstrap(AppComponent);
        observer.disconnect();
      }
    });

    const targetNode = document.body;
    const observerConfig = {childList: true, subtree: true};
    mutationObserver.observe(targetNode, observerConfig);
  }
}
